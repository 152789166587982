.datepicker-placeholder {
  cursor: pointer;
  position: relative;
  outline: none;
}
.datepicker-placeholder .reset {
  display: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -9px;
  background-color: rgba(100, 100, 100, 0.2);
}
.datepicker-placeholder .reset::before, .datepicker-placeholder .reset::after {
  position: absolute;
  content: "";
  display: block;
  transform: rotate(45deg);
}
.datepicker-placeholder .reset::before {
  width: 12px;
  left: 3px;
  top: 50%;
  margin-top: -1px;
  border-top: 2px solid #999;
}
.datepicker-placeholder .reset::after {
  height: 12px;
  top: 3px;
  left: 50%;
  margin-left: -1px;
  border-left: 2px solid #999;
}
.datepicker-placeholder .calendar {
  position: absolute;
  z-index: 10;
  top: -3px;
  left: -10px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  display: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.datepicker-placeholder .calendar.active {
  display: block;
}
.datepicker-placeholder .calendar .calendar__header {
  border-bottom: 1px solid #888;
  background-color: #006679;
  padding: 10px;
}
.datepicker-placeholder .calendar .calendar__header__inner {
  display: flex;
  justify-content: space-between;
}
.datepicker-placeholder .calendar .calendar__header__inner > * {
  flex: 1 1 50%;
  max-width: calc(50% - 10px);
}

.calendar-top .datepicker-placeholder .calendar {
  top: auto;
  bottom: -3px;
}

.wh-monthtable {
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 308px;
  cursor: default;
}
.wh-monthtable td, .wh-monthtable th {
  font-size: 15px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  width: 42px;
  height: 42px;
}
.wh-monthtable .wh-monthtable__weekday {
  font-weight: normal;
}
.wh-monthtable .wh-monthtable__day {
  color: #006679;
  border-top: 1px solid #e0e0e0;
  transition: color 0.3s, background-color 0.3s;
}
.wh-monthtable .wh-monthtable__day + .wh-monthtable__day {
  border-left: 1px solid #e0e0e0;
}
.wh-monthtable .wh-monthtable__day:not(.wh-monthtable__day--disabled) {
  cursor: pointer;
}
.wh-monthtable .wh-monthtable__day:not(.wh-monthtable__day--disabled):hover, .wh-monthtable .wh-monthtable__day.wh-monthtable__day--selected {
  background-color: #006679;
  color: #fff;
}
.wh-monthtable .wh-monthtable__day--previousmonth, .wh-monthtable .wh-monthtable__day--nextmonth, .wh-monthtable .wh-monthtable__day--disabled {
  color: #999;
  background-color: #F3F3F3;
}
.wh-monthtable .wh-monthtable__day--disabled {
  opacity: 0.4;
}