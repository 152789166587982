html {
  background: #0b6c7f url("./web/img/background480.jpg") no-repeat 0 100%;
  background-size: 100%;
  min-height: 100%;
  display: flex;
}
@media (min-width: 480px) {
  html {
    background-image: url("./web/img/background960.jpg");
  }
}
@media (min-width: 960px) {
  html {
    background-image: url("./web/img/background960.jpg");
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;
  width: 100vw;
  min-height: 100vh;
  padding: 20px;
}

.questionspreview + .questionspreview {
  page-break-before: always;
}

html.page-questions body {
  padding-top: 60px;
  padding-bottom: 60px;
}

main {
  max-width: 1069px;
  width: 100%;
}
@media (max-width: 1000px) {
  main {
    max-width: 670px;
  }
}

.logo {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 50px;
  width: 244px;
  height: 53px;
}
.logo > img {
  width: 100%;
  height: auto;
}
html.page-questions .logo, html.page-result .logo {
  position: absolute;
  top: -57px;
  left: 0;
  width: 203px;
}
@media (max-width: 1000px) {
  html.page-questions .logo, html.page-result .logo {
    position: relative;
    top: 30px;
    width: 244px;
    height: 53px;
  }
}

.errorpage {
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.errorpage__content {
  background-color: #fff;
  color: #006679;
  padding: 20px;
}

.introduction {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}
.introduction .languageswitch {
  display: block;
  list-style-type: none;
  text-align: center;
  margin: 50px 0 30px -30px;
  padding: 0;
  white-space: nowrap;
}
.introduction .languageswitch > li {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  transition: text-shadow 0.2s;
  margin-left: 30px;
  margin-bottom: 20px;
  opacity: 0.8;
  transition: opacity 0.2s;
}
.introduction .languageswitch > li:hover {
  text-shadow: 0 0 1px #fff;
  opacity: 1;
}
.introduction .languageswitch > li img {
  width: 60px;
  height: 30px;
  margin-bottom: 10px;
  transition: transform 0.3s;
}
.introduction .languageswitch > li:hover > img {
  transform: translateY(-4px);
}
.introduction .languageswitch > li span {
  display: block;
}
@media (max-width: 500px) {
  .introduction .languageswitch {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    max-width: 320px;
    margin: 40px auto 20px;
  }
  .introduction .languageswitch > li {
    position: relative;
    left: -15px;
    flex: 1 1 50%;
    max-width: calc(50% - 30px);
  }
}
.introduction__item {
  display: none;
}
.introduction__item.active {
  display: block;
}
.introduction__item.active[data-language=nl] > .languageswitch > li[data-language=nl], .introduction__item.active[data-language=de] > .languageswitch > li[data-language=de], .introduction__item.active[data-language=en] > .languageswitch > li[data-language=en], .introduction__item.active[data-language=pl] > .languageswitch > li[data-language=pl] {
  text-shadow: 0 0 1px #fff;
  opacity: 1;
}
.introduction .button {
  min-width: 335px;
}

.button, .wh-form__button {
  background-color: #065969;
  -webkit-appearance: none;
  box-shadow: none;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  display: inline-block;
  font-size: 21px;
  line-height: 54px;
  height: 54px;
  white-space: nowrap;
  overflow: hidden;
  text-align: top;
  text-overflow: ellipsis;
  color: #fff;
  border-radius: 0;
  border: 0 none;
  min-width: 150px;
  max-width: 100%;
  padding: 0 14px;
  transition: text-shadow 0.2s;
  vertical-align: top;
  text-align: center;
}
.button--disabled, .wh-form__button--disabled {
  cursor: default;
  opacity: 0.3;
}
.button:not(.button--disabled):hover, .wh-form__button:not(.button--disabled):hover {
  text-shadow: 0 0 1px #fff;
}
@media (max-width: 480px) {
  .button, .wh-form__button {
    min-width: 110px;
  }
}

@media (max-width: 1000px) {
  .logo {
    margin-bottom: 20px;
  }
  .button, .wh-form__button {
    font-size: 18px;
  }
  h1 {
    font-size: 26px;
  }
}
@media (max-width: 620px) {
  body {
    padding: 20px 0;
  }
  html.page-questions body,
  html.page-result body {
    padding: 0;
  }
  .introduction {
    padding: 0 20px;
  }
}