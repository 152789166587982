.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

:-ms-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

input:-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

.rpc__busyoverlay {
  display: none;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  height: 80px;
  width: 80px;
  background: transparent url(../../web/img/spinner.png) 50% 50% no-repeat;
  background-size: contain;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.twence-dialog {
  background: white;
  padding: 30px;
  min-width: 450px;
  max-width: 600px;
  color: #000;
}
.twence-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.twence-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.twence-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
@media (max-width: 450px) {
  .twence-dialog {
    min-width: 100vw;
    max-width: 100%;
    padding: 20px;
  }
}
.twence-dialog .message > *:last-child {
  margin-bottom: 0;
}
.twence-dialog h2 {
  margin-bottom: 10px;
}
.twence-dialog .wh-form__fieldgroup {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.twence-dialog .error {
  color: #C36E94;
}
.twence-dialog .buttons {
  display: flex;
  justify-content: space-between;
}
.twence-dialog .wh-form.wh-styledinput .wh-form__buttongroup {
  padding: 0;
}
.twence-dialog .wh-form.wh-styledinput .wh-form__fields {
  width: 100%;
}
.twence-dialog .wh-form.wh-styledinput input {
  width: 100%;
}
.twence-dialog .wh-form.wh-styledinput .button {
  width: 100%;
}

.wh-form.wh-styledinput {
  z-index: 0;
  position: relative;
  /* date/time */
  /* css styled pulldown */
  /* radio / checkbox styling */
}
.wh-form.wh-styledinput .columns {
  display: flex;
}
.wh-form.wh-styledinput .columns > div {
  margin-right: 15px;
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
}
.wh-form.wh-styledinput .columns > div + div {
  margin-right: 0;
  margin-left: 15px;
}
@media (max-width: 620px) {
  .wh-form.wh-styledinput .columns {
    display: block;
  }
  .wh-form.wh-styledinput .columns > div {
    margin-right: 0;
    max-width: 100%;
  }
  .wh-form.wh-styledinput .columns > div + div {
    margin-left: 0;
  }
}
.wh-form.wh-styledinput h3 {
  font-size: 21px;
}
.wh-form.wh-styledinput .wh-form__remark {
  font-size: 13px;
  padding-bottom: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form.wh-styledinput .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__optionlabel, .wh-form.wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-form.wh-styledinput .wh-form__fieldgroup {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  padding-bottom: 4px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  flex: none;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.wh-form.wh-styledinput select + .wh-form__subfield > label,
.wh-form.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form.wh-styledinput input, .wh-form.wh-styledinput select,
.wh-form.wh-styledinput textarea,
.wh-form.wh-styledinput .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__timeinputgroup,
.wh-form.wh-styledinput .datepicker-placeholder {
  border: 1px solid #006679;
  border-radius: 0;
  min-height: 30px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 10px;
  max-width: 100%;
  color: #006679;
  flex: 1;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-form.wh-styledinput input:focus, .wh-form.wh-styledinput input.focus, .wh-form.wh-styledinput input:hover, .wh-form.wh-styledinput select:focus, .wh-form.wh-styledinput select.focus, .wh-form.wh-styledinput select:hover,
.wh-form.wh-styledinput textarea:focus,
.wh-form.wh-styledinput textarea.focus,
.wh-form.wh-styledinput textarea:hover,
.wh-form.wh-styledinput .wh-form__dateinputgroup:focus,
.wh-form.wh-styledinput .wh-form__dateinputgroup.focus,
.wh-form.wh-styledinput .wh-form__dateinputgroup:hover,
.wh-form.wh-styledinput .wh-form__timeinputgroup:focus,
.wh-form.wh-styledinput .wh-form__timeinputgroup.focus,
.wh-form.wh-styledinput .wh-form__timeinputgroup:hover,
.wh-form.wh-styledinput .datepicker-placeholder:focus,
.wh-form.wh-styledinput .datepicker-placeholder.focus,
.wh-form.wh-styledinput .datepicker-placeholder:hover {
  border-color: #006679;
}
.wh-form.wh-styledinput input:disabled, .wh-form.wh-styledinput input[data-wh-form-disabled], .wh-form.wh-styledinput select:disabled, .wh-form.wh-styledinput select[data-wh-form-disabled],
.wh-form.wh-styledinput textarea:disabled,
.wh-form.wh-styledinput textarea[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__dateinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__timeinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__timeinputgroup[data-wh-form-disabled],
.wh-form.wh-styledinput .datepicker-placeholder:disabled,
.wh-form.wh-styledinput .datepicker-placeholder[data-wh-form-disabled] {
  color: #E7E6EC;
  border-color: #E7E6EC;
  background-color: #F8F8F8;
  box-shadow: none;
}
.wh-form.wh-styledinput textarea {
  min-height: 140px;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup, .wh-form.wh-styledinput .wh-form__timeinputgroup {
  height: 32px;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup .wh-form__dateinputgroup__line::after,
.wh-form.wh-styledinput .wh-form__dateinputgroup .wh-form__timeinputgroup__line::after, .wh-form.wh-styledinput .wh-form__timeinputgroup .wh-form__dateinputgroup__line::after,
.wh-form.wh-styledinput .wh-form__timeinputgroup .wh-form__timeinputgroup__line::after {
  top: 4px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input, .wh-form.wh-styledinput .wh-form__fieldgroup--error select,
.wh-form.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .datepicker-placeholder {
  border-color: #C36E94;
  background-color: #f9f3f6;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error select + .arrow {
  background-color: #f9f3f6;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__pulldown-styled::after {
  background: -moz-linear-gradient(left, rgba(252, 248, 250, 0) 0%, rgb(252, 248, 250) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 148, 0) 0%, rgb(252, 248, 250) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(252, 248, 250, 0) 0%, rgb(252, 248, 250) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00fcf8fa", endColorstr="#fcf8fa",GradientType=1 ); /* IE6-9 */
}
.wh-form.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #C36E94;
  padding: 8px 0 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup, .wh-form.wh-styledinput .wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup input, .wh-form.wh-styledinput .wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup__line::after, .wh-form.wh-styledinput .wh-form__timeinputgroup__line::after {
  top: 12px;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled {
  position: relative;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select {
  padding-right: 40px;
  width: 100%;
  margin: 0;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form.wh-styledinput .wh-form__pulldown-styled .arrow {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  background: #ffffff url(../../web/img/arrow-down.png) no-repeat 50% 50%;
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  width: 40px;
  z-index: 3;
  pointer-events: none;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled::after {
  display: block;
  content: "";
  width: 25px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 28px;
  top: 3px;
  bottom: 3px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 ); /* IE6-9 */
}
.wh-form.wh-styledinput input[type=radio],
.wh-form.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-form.wh-styledinput input[type=radio] + label,
.wh-form.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 3px;
  border-radius: 0;
  width: 20px;
  max-width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border: 1px solid #006679;
  background-color: #fff;
}
.wh-form.wh-styledinput input[type=radio] + label::after,
.wh-form.wh-styledinput input[type=checkbox] + label::after {
  font-family: "CEPHEI-Iconfont";
  content: "";
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  color: #006679;
  font-size: 14px;
  display: block;
  padding: 1px 0 0 2px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  transition: all 0.3s;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label,
.wh-form.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #E7E6EC;
  cursor: default;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label + *,
.wh-form.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #006679;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-form.wh-styledinput input[type=radio] + label::after {
  border: 3px solid #fff;
  border-radius: 50%;
}
.wh-form.wh-styledinput input[type=radio]:checked + label::after {
  background-color: #006679;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F8F8F8;
  border-color: #F8F8F8;
}
.wh-form.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #E7E6EC;
}
.wh-form.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\e907";
  color: #006679;
}
.wh-form.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-form.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F8F8F8;
}
.wh-form.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\e907";
  color: #E7E6EC;
}
.wh-form.wh-styledinput input[type=checkbox]:not([disabled]):focus + label,
.wh-form.wh-styledinput input[type=radio]:not([disabled]):focus + label,
.wh-form.wh-styledinput input[type=checkbox]:not([disabled]):hover + label,
.wh-form.wh-styledinput input[type=radio]:not([disabled]):hover + label {
  border-color: #006679;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #C36E94;
  background-color: #f9f3f6;
}
.wh-form.wh-styledinput .wh-form__buttongroup {
  text-align: center;
  padding: 20px 0 0;
  margin-bottom: 0;
}
.wh-form.wh-styledinput .wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 30px;
}

.spinner {
  -webkit-animation: spinner 2s infinite linear;
  animation: spinner 2s infinite linear;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}