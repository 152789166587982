.safetytest {
  color: #006679;
  position: relative;
}
.safetytest__header {
  text-align: center;
}
.safetytest__header .userinfo {
  position: absolute;
  width: 60%;
  top: -40px;
  height: 25px;
  right: 0;
  padding: 0 15px;
  display: none;
  font-size: 14px;
  line-height: 25px;
}
html.page-questions .safetytest__header .userinfo, html.page-result .safetytest__header .userinfo {
  display: block;
}
.safetytest__header .userstate {
  white-space: nowrap;
  text-align: left;
}
.safetytest__header .userstate > span {
  display: inline-block;
  vertical-align: top;
}
.safetytest__header .userstate .name {
  max-width: calc(100% - 240px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.safetytest__header .userstate .logout {
  cursor: pointer;
  margin-left: 5px;
}
.safetytest__header .userstate .logout:hover {
  text-decoration: underline;
}
.safetytest__header .selectedlanguage {
  display: inline-block;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
}
.safetytest__header .selectedlanguage > img {
  height: 25px;
  width: 50px;
}
.safetytest__header .selectedlanguage > span {
  display: inline-block;
  padding: 0 15px;
  vertical-align: top;
}
@media (max-width: 1000px) {
  .safetytest__header .userinfo {
    position: absolute;
    bottom: -90px;
    left: 0;
    right: 0;
    top: auto;
    height: 90px;
    width: 100%;
    color: #fff;
    padding: 12px 20px 0;
  }
  .safetytest__header .userstate {
    text-align: center;
  }
  .safetytest__header .userstate .name {
    max-width: calc(100% - 90px);
  }
  .safetytest__header .selectedlanguage {
    margin-top: 10px;
    display: block;
    position: relative;
    background-color: transparent;
  }
}
.safetytest__page--registration {
  background-color: #fff;
  padding: 30px;
  max-width: 583px;
  margin: 0 auto;
}
.safetytest__page .videointro {
  text-align: center;
  color: #fff;
}
.safetytest__page #safetyvideo {
  margin: 0 auto;
}
.safetytest__page__buttons {
  margin-top: 30px;
  text-align: center;
}
.safetytest__page__buttons .button + .button {
  margin-left: 30px;
}
@media (max-width: 620px) {
  .safetytest__page--registration, .safetytest__page--video {
    padding: 15px;
  }
  .safetytest__page__buttons {
    display: flex;
    justify-content: space-between;
  }
}

.questionslist {
  position: relative;
  margin-top: 57px;
  margin-bottom: 57px;
}
.questionslist .safetytest__page__buttons {
  display: flex;
  justify-content: space-between;
}
.questionslist::before {
  background-color: #fff;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -57px;
  bottom: -57px;
  width: 60%;
  z-index: -1;
}
.questionslist__item {
  display: none;
  flex-flow: row;
  align-items: center;
}
.questionslist__item.active {
  display: flex;
}
.questionslist__item .image {
  flex: 1 1 60%;
  max-width: 60%;
  margin-right: 30px;
  align-self: stretch;
  overflow: hidden;
  position: relative;
}
.questionslist__item .image > img {
  height: 100%;
  position: absolute;
  top: 0;
  left: -9999px;
  right: -9999px;
  vertical-align: bottom;
  margin: 0 auto;
}
.questionslist__item .content {
  flex: 1 1 40%;
  max-width: 40%;
  padding-right: 30px;
  background-color: #fff;
}
.questionslist__item .question {
  font-size: 22px;
  padding: 10px 0 15px;
}
.questionslist__item .answeroption {
  position: relative;
  padding-left: 45px;
  min-height: 32px;
}
.questionslist__item .answeroption + .answeroption {
  margin-top: 15px;
}
.questionslist__item .answeroption input {
  visibility: hidden;
  position: absolute;
}
.questionslist__item .answeroption label {
  display: flex;
  align-items: center;
  min-height: 32px;
  cursor: pointer;
}
.questionslist__item .answeroption label::before,
.questionslist__item .answeroption label::after {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  border-radius: 50%;
  top: 50%;
}
.questionslist__item .answeroption label::before {
  border: 1px solid #979797;
  left: 0;
  margin-top: -16px;
  width: 32px;
  height: 32px;
}
.questionslist__item .answeroption label::after {
  margin-top: -11px;
  left: 5px;
  width: 22px;
  height: 22px;
}
.questionslist__item .answeroption input:checked + label::after {
  background-color: #0A6C7E;
}
.questionslist .progress {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  margin-top: 12px;
  left: 0;
  max-width: 40%;
}
.questionslist .progress > span {
  cursor: default;
  display: inline-block;
  height: 28px;
  min-width: 28px;
  text-align: center;
  font-size: 15px;
  line-height: 28px;
  margin-top: 15px;
  color: #000;
  margin-right: 14px;
  background-color: rgba(255, 255, 255, 0.6);
}
.questionslist .progress > span.active {
  background-color: rgb(255, 255, 255);
}
@media (max-width: 1000px) {
  .questionslist {
    margin-top: 40px;
  }
  .questionslist::before {
    display: none;
  }
  .questionslist__item.active {
    display: block;
  }
  .questionslist__item .image {
    display: block;
    max-width: 100%;
    overflow: visible;
    margin-right: 0;
  }
  .questionslist__item .image > img {
    position: relative;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
  }
  .questionslist__item .content {
    max-width: 100%;
    padding: 20px;
  }
  .questionslist .progress {
    display: none;
  }
}

.testresults {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 470px;
  /*
    &--success .leftpanel
    {
      padding: 0px;
      background: transparent url("../../web/img/certpreview.png") no-repeat 50% 0;
      background-size: cover;
      min-height: 470px;
    }
  */
}
.testresults::before {
  background-color: #fff;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -57px;
  bottom: -57px;
  width: 60%;
  z-index: -1;
}
.testresults .leftpanel {
  background-color: #fff;
  flex: 1 1 60%;
  max-width: 60%;
  margin-right: 30px;
  max-height: 470px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.testresults .rightpanel {
  background-color: #fff;
  flex: 1 1 40%;
  max-width: 40%;
  padding-right: 30px;
}
.testresults .rightpanel h1 {
  margin-bottom: 0;
  margin-top: 10px;
}
.testresults .rightpanel p {
  text-align: center;
  padding: 20px 0;
}
.testresults .rightpanel p + p {
  padding-top: 0;
}
.testresults .rightpanel .button {
  width: 100%;
  margin-top: 15px;
}
.testresults .rightpanel .button.mobile {
  display: none;
}
.testresults .title {
  font-size: 22px;
  padding: 10px 30px 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.testresults .title span {
  display: inline-block;
  align-self: flex-end;
}
.testresults .title span + span {
  padding-left: 10px;
}
.testresults .leftpanel .scrollcontainer {
  color: #fff;
  overflow: hidden;
  overflow-y: scroll;
  height: 410px;
}
.testresults .leftpanel .scrollcontainer > div {
  padding: 10px 30px;
}
.testresults .leftpanel .scrollcontainer > div > div + div {
  padding-top: 5px;
}
.testresults .leftpanel .scrollcontainer > div + div {
  margin-top: 15px;
}
.testresults .leftpanel .scrollcontainer .wrong {
  background-color: #FF2201;
}
.testresults .leftpanel .scrollcontainer .correct {
  background-color: #89BA22;
}
@media (max-width: 1000px) {
  .testresults {
    margin-top: 40px;
    flex-flow: column;
    background-color: #fff;
    align-self: flex-start;
  }
  .testresults .title {
    display: block;
    text-align: center;
  }
  .testresults .title span {
    display: block;
  }
  .testresults .title span + span {
    padding-left: 0;
    font-size: 35px;
  }
  .testresults::before {
    display: none;
  }
  .testresults .leftpanel, .testresults .rightpanel {
    min-height: 0;
    max-height: auto;
    max-width: 100%;
    margin-right: 0;
    flex-basis: 100%;
  }
  .testresults .rightpanel > .leftpanel {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .testresults .rightpanel > .leftpanel + .buttons > .button[data-action=showanswers] {
    display: none;
  }
  .testresults .leftpanel {
    display: none;
    box-shadow: none;
    padding: 0 20px;
  }
  .testresults--failed .leftpanel {
    display: block;
    order: 2;
    margin-top: -600px;
  }
  .testresults--failed .buttons {
    padding-top: 420px; /* space for errors */
  }
  .testresults .rightpanel {
    padding: 20px;
  }
  .testresults .rightpanel .button.mobile {
    display: block;
  }
}

.embeddedwidget-video__player {
  position: relative;
  background-color: #3e3e3e;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer;
}

.embeddedwidget-video__player::after {
  content: "";
  display: block;
  padding-top: 56.25%; /* default 16:9 */
}

.embeddedwidget-video--aspect_4_3 .embeddedwidget_video__player::after {
  padding-top: 75%;
}

.embeddedwidget-video__player__play {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 70px;
  height: 70px;
  margin: -35px 0 0 -35px;
  background-color: #9E7B3F;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.embeddedwidget-video__player__play::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: 23px;
  border-top: 15px solid transparent;
  border-left: 24px solid #fff;
  border-bottom: 15px solid transparent;
}

.embeddedwidget-video__player:hover > .embeddedwidget-video__player__play {
  opacity: 1;
}

.embeddedwidget-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}