@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:400,400i,700');

html
{
  font: 16px 'Merriweather Sans', Arial, Sans-Serif;
  color: #fff;
}

a
{
  color: inherit;
}

h1, h2, h3
{
  line-height: 120%;
}
h1
{
  font-weight: normal;
  font-size: 42px;
  margin-bottom: 20px;
  text-align: center;
}
h2
{
  font-size: 22px;
}
h3
{
  font-size: 18px;
}

p a
{
  color: inherit;
}

p
{
  margin-bottom: 20px;
  line-height: 160%;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0 0 20px;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}
